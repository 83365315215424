import React, { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputMask from 'react-input-mask';
import Divider from '@mui/material/Divider';
import { useTelegram } from '../../hooks/useTelegram';
import AspectRatio from '@mui/joy/AspectRatio';
import { useNavigate } from "react-router-dom";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import ColorCircle from '../ColorCircle/ColorCircle';


import {  RadioGroup } from '@mui/material';
import './TagForm.css'; // Импортируем стили
import { color } from '@mui/system';



function TagForm() {
  const {tg, queryId} = useTelegram();
  const navigate = useNavigate();
  const [itemData, setItemData] = React.useState([]); 
  const [colorsData, setColorsData] = React.useState([]); 
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [selectedColorText, setSelectedColorText] = React.useState("");
  const [selectedShapeColor, setSelectedShapeColor] = React.useState({}); 
  const [selectedShape, setSelectedShape] = React.useState({}); 
  const [petName, setPetName] = React.useState(''); 
  const [phoneFirst, setPhoneFirst] = React.useState(''); 
  const [phoneSecond, setPhoneSecond] = React.useState(''); 
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [useOwnForm, setUseOwnForm] = React.useState(false);
  const [formSelected, setFormSelected] = React.useState(false);
  const [commentText, setCommentText] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ownForm, setOwnForm] = React.useState('');

  const petNameRef = useRef(null);
  const phoneFirstRef = useRef(null);
  const phoneSecondRef = useRef(null);
  const ownFormRef = useRef(null);
  const commentRef = useRef(null);
  const backUrl = process.env.REACT_APP_BACK_URL;
  
  
  const handleColorClick = async (color, shape_id) => {
    let color_id = color.id;
    setSelectedColor(color_id);
    setSelectedColorText(color.name_ru);

    try {
      setIsLoading(true);
      const response = await fetch(backUrl+'/api/v1/product/shape_color/?shape='+shape_id+'&color='+color_id);
      const jsonData = await response.json();      
      setSelectedShapeColor(jsonData[0]);
      
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }

    
  };
  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };
  const handleOwnForm = (event) => {
    const value = event.target.value.slice(0, 15); 
    setOwnForm(value);
  };
  const handlePetName = (event) => {
    const value = event.target.value.slice(0, 15); 
    setPetName(value);
  };
  const handlePhoneFirst = (event) => {
    const value = event.target.value.replace(/[^\d+]/g, '');
    setPhoneFirst(value); 
  };
  const handlePhoneSecond = (event) => {
    const value = event.target.value.replace(/[^\d+]/g, '');
    setPhoneSecond(value); 
  };

  const handleImageClick = async (item_id, code='') => {
    try {
      console.log("selectedColor",selectedColor);
      console.log("selectedImage",selectedImage);
      setIsLoading(true);
      setSelectedShapeColor({});
      const response = await fetch(backUrl+'/api/v1/product/shape_color/?shape='+item_id);
      const jsonData = await response.json();
      // console.log('get data')
      // console.log(jsonData);
      setColorsData(jsonData);
      setUseOwnForm(false);
      console.log("code", code);
      if (code == 'NO_FORM'){      
        setUseOwnForm(true);
      }
      
      
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
    console.log("item_id",item_id);

    setSelectedImage(item_id);
    setFormSelected(true);
  };
  const getImagePath = (item) => {
    return backUrl+item.image;
  };  

  const handleCheckout = () => {
    if (selectedColor === null)    
    {
      alert('Выберите пожалуйста цвет'); return;
    }
    if (petName === '')
    {
      alert('Заполните пожалуйста кличку животного'); return;
    }
    if (phoneFirst === '')
    {
      alert('Заполните пожалуйста телефон');return;
    }

    const data = {selectedColor, selectedColorText, selectedImage, petName, phoneFirst, phoneSecond, commentText, ownForm};
    console.log(data);
    navigate('/order_detail', { state: data });
  };
  
  
  useEffect(() => {
    console.log('starting app ...');

    let cleanup = false;
        (async () => {
            try {
                setIsLoading(true);
                const response = await fetch(backUrl+'/api/v1/product/shape_color_unified/');
                const jsonData = await response.json();
                // console.log('get data')
                if (!cleanup) {
                    // console.log(jsonData);
                    setItemData(jsonData);                    
                }
            } catch {
              setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        })()
        // console.log('222');
        return () => {
            cleanup = true
        }

  },[]);
  
  return (    
      <Container className="container" maxWidth="sm" sx={{paddingTop:'10px'}}>
      <Card
            sx={{
              padding: '10px',
              textAlign: 'center',
              alignItems: 'center',   
              overflow: 'hidden',              
              '--icon-size': '100px'              
            }}
          >
            <CardOverflow variant="solid" color="primary">
              <AspectRatio
                variant="outlined"
                color="warning"
                ratio="1"
                sx={{
                  m: 'auto',
                  transform: 'translateY(50%)',
                  borderRadius: '50%',
                  width: 'var(--icon-size)',
                  boxShadow: 'sm',
                  bgcolor: 'background.surface',
                  position: 'relative',
                  overflow:'hidden'
                }}
              >                
                <div className={`image-preview`} style={{backgroundImage: `url('./media/img/logo.jpg')`}}></div>
              </AspectRatio>
            </CardOverflow>
            <CardContent sx={{ maxWidth: '40ch' }}>
              <Box style={{paddingTop:'40px'}}>
                <h3>Выберите форму адресника</h3>
                <ImageList className="form-select" cols={itemData.length}>            
                {itemData
                  .filter((item, index, self) => self.findIndex((i) => i.shape.id === item.shape.id) === index)
                  .map((item) => (
                    <ImageListItem className="image-list" key={item.shape.id} onClick={() => handleImageClick(item.shape.id, item.shape.code)}>
                      <div className={`image-preview ${selectedImage === item.shape.id ? 'selected-image' : ''}`}
                        style={{
                          backgroundImage: `url(${getImagePath(item)})`,                    
                        }}
                      ></div>
                    </ImageListItem>
                ))}
              </ImageList>
                {formSelected && // Рендерим элементы только если форма выбрана
                  <div>
                  <Divider />
                  
                  {useOwnForm ? <div>
                    <h4>Опишите форму, которую вы хотите</h4>
                    <div className='text-inputs'>
                    <TextField              
                          className="text-field"
                          label="Опишите форму"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={ownForm}
                          onChange={handleOwnForm}
                          inputRef={ownFormRef}
                        />
                      </div>
                    <Divider />
                  </div>:<></>}

                  <h3>Основной цвет</h3>
                    <RadioGroup
                      className="color-radio-group"
                      aria-label="color"
                      name="color"
                      value={selectedColor}                      
                      row
                      >{colorsData.map((item) => (
                        <ColorCircle                           
                          key={item.color.id} 
                          colorkey={item.color.id} 
                          color={item.color.value} 
                          selectedColor={selectedColor}
                          onClick={() =>handleColorClick(item.color,item.shape.id)} 
                        />
                      ))}
                    </RadioGroup>
                    
                    <Divider />
                    <br></br>
                      {selectedShapeColor && selectedShapeColor.image ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <div
                            className={`image-preview selected-image`}
                            style={{
                              width: "100px",  // Set the desired width
                              height: "100px", // Set the desired height
                              backgroundImage: `url(${selectedShapeColor.image})`,        
                            }}
                          ></div>
                        </div>
                      ) : <div></div>}

                    <div className='text-inputs'>
                      <h3>Данные</h3>
                      <TextField
                          className="text-field"
                          label="Кличка (максимум 15 символов)"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={petName}
                          onChange={handlePetName}
                          inputProps={{ maxLength: 15 }} // Ограничение в 15 символов
                          placeholder="Максимум 15 символов"
                          error={petName.length > 15} // Показать ошибку, если превышен лимит
                          helperText={petName.length > 15 ? "Превышен лимит символов" : ""}
                          inputRef={petNameRef}
                        />
                      <TextField
                        className="text-field"
                        label="Телефон"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={phoneFirst}
                        onChange={handlePhoneFirst}
                        InputProps={{
                          inputComponent: InputMask, 
                          inputProps: {
                            mask: '+7 999 999 99 99', // Указываем маску
                          }
                        }}
                        inputRef={phoneFirstRef}
                      />
                      <TextField
                        className="text-field"
                        label="Второй телефон (если есть)"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={phoneSecond}
                        onChange={handlePhoneSecond}
                        InputProps={{
                          inputComponent: InputMask,
                          inputProps: {
                            mask: '+7 999 999 99 99',
                          }
                        }}
                        inputRef={phoneSecondRef}
                      />
                      <div className='text-area-div'>
                        <TextareaAutosize
                          className="text-area"                        
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Комментарий к заказу"
                          ref={commentRef}
                          value={commentText}
                          onChange={handleCommentChange}
                        />
                      </div>
                    </div>
                    <h4>Стоимость: 5000 Т (без учета доставки)</h4>
                    <button type="submit" className="submit-button" onClick={handleCheckout}>Отправить</button>                     
                </div>
                }
              </Box>
            </CardContent>          
          </Card>
      </Container>
  );
}

export default TagForm;
