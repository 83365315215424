import { useEffect } from 'react';
import './App.css';
import { useTelegram } from './hooks/useTelegram';
import { Route, Routes } from 'react-router-dom';
import TagForm from './components/TagForm/TagForm';
import OrderDetail from './components/OrderDetail/OrderDetail';

function App() {

  const {tg} = useTelegram();

  useEffect(()=>{
    tg.ready();
  },[])

  return (    
    <div className="app">     
      <Routes>
        <Route path={'form'} element={<TagForm />}/>        
        <Route path={'order_detail'} element={<OrderDetail />}/>      
      </Routes>
    </div>    
  );
}

export default App;
