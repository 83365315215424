import React from 'react';

const ColorCircle = ({ colorkey, color, selectedColor, onClick }) => {    
  // console.log("colorkey", colorkey);
  // console.log("selectedColor", selectedColor);
  return (
    <div
      key={{colorkey}}
      colorkey={{colorkey}}
      className={`color-circle ${colorkey === selectedColor ? 'selected' : ''}`}
      style={{ backgroundColor: color }}
      onClick={() => onClick(colorkey)}
    ></div>
  );
};

export default ColorCircle;