import React, { useCallback, useState, useEffect } from "react";
import './OrderDetail.css';
import Spinner from "../Spinner/Spinner";
import { useParams, useHistory, useLocation   } from 'react-router-dom';
import { useTelegram } from "../../hooks/useTelegram"; 
import InputMask from 'react-input-mask';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const OrderDetail = (state) => {
    const location = useLocation();    
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [order, setOrder] = useState({});
    const [products, setProducts] = useState([]);
    const [orderData, setOrderData] = useState({});
    
    const {tg, user, queryId} = useTelegram();
    const [lastName, setLastName] = useState(user?.last_name);
    const [firstName, setFirstName] = useState(user?.first_name);
    const [selectedColor, setSelectedColor] = React.useState('black'); 
    const [selectedShapeColor, setSelectedShapeColor] = React.useState({}); 
    
    const [phoneNumber, setPhoneNumber] = useState('');    
    const [isFormValid, setIsFormValid] = useState(true);
    
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const dataFromStore = location.state;
    const backUrl = process.env.REACT_APP_BACK_URL;
    
    
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);        
      };
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handlePhoneNumberChange = (event) => {

        const inputPhoneNumber = event.target.value;
        const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '');        
        const formattedPhoneNumber = formatPhoneNumber(numericPhoneNumber);
        setPhoneNumber(formattedPhoneNumber);        
    };

    const formatPhoneNumber = (phoneNumber) => {
        const formattedNumber = phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');    
        return formattedNumber;
      };

    const checkFormValidity = () => {
        if (lastName == '' || firstName == '' || phoneNumber == '')
        {
          alert('что-то не заполнено');
        }        
      };
        
    useEffect(() => {
        console.log('555');
        
        let cleanup = false;        
        console.log('data', dataFromStore);

        (async () => {
          setOrderData(dataFromStore);
          setPhoneNumber(dataFromStore.phoneFirst);
          let shape_id = dataFromStore.selectedImage;
          let color_id = dataFromStore.selectedColor;

          try {
            setIsLoading(true);
            const response = await fetch(backUrl+'/api/v1/product/shape_color/?shape='+shape_id+'&color='+color_id);
            const jsonData = await response.json();        
            setSelectedShapeColor(jsonData[0]);          
          } catch {
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        })();
     
        console.log('666');
        return () => {
            cleanup = true;
        }
    }, []);

    const handleSubmit = useCallback(()=>{        
        if (lastName == '' || firstName == '' || phoneNumber == '')
        {
          alert('что-то не заполнено');
          return;
        }        
        setIsSubmitLoading(true);
        
        let tg_user_id = '121902864';
        if (user?.id)
            tg_user_id = user?.id;

        const data = {
          last_name: lastName,
          first_name: firstName,
          contact_phone: phoneNumber,
          pet_name: orderData.petName,
          shape: orderData.selectedImage,
          shape_manual: orderData.ownForm,
          color: orderData.selectedColor,
          phone_first: orderData.phoneFirst,
          phone_second: orderData.phoneSecond,          
          comment: orderData.commentText,
          tg_user_id: tg_user_id
        }; 
        console.log('order sended');
    
        fetch(backUrl+'/api/v1/order/order/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',            
          },
          // credentials: 'include',
          body: JSON.stringify({data}),
        }).then(response => {
          if (!response.ok) {
            throw new Error('Request failed');
            tg.MainButton.setParams({
              text:'Произошла ошибка. Попробуйте позже'
          });
          }
          // Дополнительная обработка ответа, если необходимо
          console.log('Request successful');
          tg.close();
        }).catch(error => {
          console.error('Error:', error);
        });
      }, [lastName, firstName, orderData]);
    

    const color =  orderData.selectedColor;
    const getImagePath = (item, selectedColor) => {
      return item.img[selectedColor];
    };
    
    return (
        
        <div className="order-detail">
          {isLoading ? (
            <Spinner />
          ) : (

      <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography  variant="h5" color="text.primary" gutterBottom>
              Оформление заказа
              </Typography>
              <Divider/>
              <Typography variant="h6" component="div">
                  <div>Форма адресника:
                  {selectedShapeColor && selectedShapeColor.image ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <div
                            className={`image-preview selected-image`}
                            style={{
                              width: "100px",  // Set the desired width
                              height: "100px", // Set the desired height
                              backgroundImage: `url(${selectedShapeColor.image})`,        
                            }}
                          ></div>                          
                        </div>
                      ) : <div></div>}

                  </div>
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
              <div>
                <div>Цвет: {dataFromStore.selectedColorText}</div>
                <div>Кличка: {orderData.petName}</div>
                <div>Телефон 1: {orderData.phoneFirst}</div>
                <div>Телефон 2: {orderData.phoneSecond ? orderData.phoneSecond : "Не заполнено"}</div>
                <div>Комментарий к заказу: {orderData.commentText}</div>
                <div>Своя форма адресника: {orderData.ownForm ? orderData.ownForm : "Нет"}</div>
            </div>
              </Typography>
              <Typography variant="h6" component="div">
              Данные получателя
              </Typography>
              <Divider/>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
              <div className="inputs-form">
                
                <div>
                    <label htmlFor="last_name">Фамилия <strong style={{color:'red'}}>*</strong></label><br></br>
                    <input required className="input" id={'last_name'} type="text" value={lastName} onChange={handleLastNameChange} placeholder="Фамилия"/>
                </div>
                <div>
                    <label htmlFor="first_name">Имя <strong style={{color:'red'}}>*</strong></label><br></br>
                    <input required className="input" id={'first_name'} type="text" value={firstName} onChange={handleFirstNameChange} placeholder="Имя" />
                </div>
                <div>
                    <label htmlFor="phone_number">Номер телефона (контактный) <strong style={{color:'red'}}>*</strong></label><br></br>
                    
                    <InputMask
                    className="input"
                    id="phone_number"
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    mask="+7 (999) 999-99-99"
                    placeholder="+7 (777) 777-77-77"
                    required
                    />
                </div>

                <div className="delivery-inputs">                    
                    
                    {isSubmitLoading ? (<Spinner />) : (
                    <button className="button" onClick={handleSubmit}>Подтвердить заказ</button>)}
                </div>
            </div>
              </Typography>
            </CardContent>
            
          </Card>

            
            
            )}
            
        </div>
    );
}
export default OrderDetail;